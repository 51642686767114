.Wrapper {
	transition-property:background-color, box-shadow, color, opacity, transform, border;
	transition-duration:300ms;
	white-space:nowrap;
	vertical-align:top;
	padding-top:4px;
	padding-bottom:4px;
	@apply text-2xs inline-flex relative font-medium cursor-pointer outline-none px-3 border-2 border-white shadow-button ease-out-cubic;
	line-height:24px;
	&:hover:not(.Wrapper--text), &.Wrapper--active:not(.Wrapper--text) {
		@apply bg-gray-200 border-gray-200;
	}
	&:hover {
		.Arrow {
			&:before, &:after {
				@apply translate-x-1;
			}
			span {
				&:before {
					width:8px;
					@apply opacity-100;
				}
			}
		}
	}
	&:focus {
		z-index:1;
	}
	&:focus:not(.Wrapper--text) {
		@apply outline-none shadow-button-focus;
	}
	&.Wrapper--no-shadow:not(:hover):not(:focus):not(.Wrapper--active) {
		@apply shadow-none;
	}
	&.Wrapper--no-padding {
		padding:0;
		border:none;
	}
	&.Wrapper--primary:not(.Wrapper--text) {
		@apply bg-primary-500 text-white border-primary-500 shadow-button-primary;
		&.Wrapper--outline {
			@apply text-primary-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-primary-700 border-primary-700;
		}
		&:focus {
			@apply shadow-button-primary-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--purple:not(.Wrapper--text) {
		@apply bg-purple-500 text-white border-purple-500 shadow-button-purple;
		&.Wrapper--outline {
			@apply text-purple-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-purple-700 border-purple-700;
		}
		&:focus {
			@apply shadow-button-purple-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--green:not(.Wrapper--text) {
		@apply bg-green-500 text-white border-green-500 shadow-button-green;
		&.Wrapper--outline {
			@apply text-green-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-green-700 border-green-700;
		}
		&:focus {
			@apply shadow-button-green-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--red:not(.Wrapper--text) {
		@apply bg-red-500 text-white border-red-500 shadow-button-red;
		&.Wrapper--outline {
			@apply text-red-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-red-700 border-red-700;
		}
		&:focus {
			@apply shadow-button-red-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--orange:not(.Wrapper--text) {
		@apply bg-orange-500 text-white border-orange-500 shadow-button-orange;
		&.Wrapper--outline {
			@apply text-orange-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-orange-700 border-orange-700;
		}
		&:focus {
			@apply shadow-button-orange-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--yellow:not(.Wrapper--text) {
		@apply bg-yellow-500 text-white border-yellow-500 shadow-button-yellow;
		&.Wrapper--outline {
			@apply text-yellow-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-yellow-700 border-yellow-700;
		}
		&:focus {
			@apply shadow-button-yellow-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--blue:not(.Wrapper--text) {
		@apply bg-blue-500 text-white border-blue-500 shadow-button-blue;
		&.Wrapper--outline {
			@apply text-blue-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-blue-700 border-blue-700;
		}
		&:focus {
			@apply shadow-button-blue-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--white:not(.Wrapper--text) {
		@apply bg-white text-gray-900 border-white;
		&.Wrapper--outline {
			@apply text-white;
			&:hover, &.Wrapper--active {
				@apply text-gray-900;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-white border-white;
		}
		&:focus {
			@apply shadow-button-focus;
		}
	}
	&.Wrapper--gray:not(.Wrapper--text) {
		@apply bg-gray-500 text-white border-gray-500;
		&.Wrapper--outline {
			@apply text-gray-500;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-gray-700 border-gray-700;
		}
		&:focus {
			@apply shadow-button-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--dark:not(.Wrapper--text) {
		@apply bg-gray-900 text-white border-gray-900;
		&.Wrapper--outline {
			@apply text-gray-900;
			&:hover, &.Wrapper--active {
				@apply text-white;
			}
		}
		&:hover, &.Wrapper--active {
			@apply bg-gray-700 border-gray-700;
		}
		&:focus {
			@apply shadow-button-focus;
		}
		.Arrow {
			&:before, &:after {
				@apply bg-white;
			}
			span {
				&:before {
					@apply bg-white;
				}
			}
		}
	}
	&.Wrapper--rounded {
		@apply rounded;
	}
	&.Wrapper--rounded-l {
		@apply rounded-l;
	}
	&.Wrapper--rounded-r {
		@apply rounded-r;
	}
	&.Wrapper--rounded-full-l {
		@apply rounded-l-full;
	}
	&.Wrapper--rounded-full-r {
		@apply rounded-r-full;
	}
	&.Wrapper--rounded-full {
		@apply rounded-full;
	}
	&.Wrapper--outline:not(.Wrapper--text) {
		background:none;
		@apply text-gray-900;
	}
	&.Wrapper--icon-only {
		padding-left:4px;
		padding-right:4px;
	}
	&.Wrapper--xs {
		padding-top:0;
		padding-bottom:0;
		@apply px-2 text-3xs;
		line-height:20px;
		&.Wrapper--icon-only {
			padding-left:0;
			padding-right:0;
		}
		.Icon {
			width:12px;
			height:12px;
			&:first-child:last-child {
				width:20px;
				height:20px;
			}
			svg {
				width:12px;
				height:12px;
			}
		}
	}
	&.Wrapper--sm {
		padding-top:0px;
		padding-bottom:0px;
		@apply px-2;
		&.Wrapper--icon-only {
			padding-left:0px;
			padding-right:0px;
		}
		.Icon {
			svg {
				width:12px;
				height:12px;
			}
		}
	}
	&.Wrapper--lg {
		padding-top:7px;
		padding-bottom:7px;
		@apply px-6 text-xs;
		&.Wrapper--icon-only {
			padding-left:7px;
			padding-right:7px;
		}
		.Icon {
			svg {
				width:16px;
				height:16px;
			}
		}
	}
	&.Wrapper--block {
		@apply w-full justify-center;
	}
	&.Wrapper--text {
		padding-left:0;
		padding-right:0;
		border-color:transparent;
		@apply font-body shadow-none;
		&.Wrapper--primary {
			@apply text-primary-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-primary-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-primary-500;
				}
				span {
					&:before {
						@apply bg-primary-500;
					}
				}
			}
		}
		&.Wrapper--purple {
			@apply text-purple-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-purple-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-purple-500;
				}
				span {
					&:before {
						@apply bg-purple-500;
					}
				}
			}
		}
		&.Wrapper--green {
			@apply text-green-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-green-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-green-500;
				}
				span {
					&:before {
						@apply bg-green-500;
					}
				}
			}
		}
		&.Wrapper--red {
			@apply text-red-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-red-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-red-500;
				}
				span {
					&:before {
						@apply bg-red-500;
					}
				}
			}
		}
		&.Wrapper--orange {
			@apply text-orange-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-orange-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-orange-500;
				}
				span {
					&:before {
						@apply bg-orange-500;
					}
				}
			}
		}
		&.Wrapper--yellow {
			@apply text-yellow-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-yellow-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-yellow-500;
				}
				span {
					&:before {
						@apply bg-yellow-500;
					}
				}
			}
		}
		&.Wrapper--blue {
			@apply text-blue-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-blue-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-blue-500;
				}
				span {
					&:before {
						@apply bg-blue-500;
					}
				}
			}
		}
		&.Wrapper--white {
			@apply text-white;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-white;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-white;
				}
				span {
					&:before {
						@apply bg-white;
					}
				}
			}
		}
		&.Wrapper--gray {
			@apply text-gray-500;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-gray-700;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-gray-500;
				}
				span {
					&:before {
						@apply bg-gray-500;
					}
				}
			}
		}
		&.Wrapper--dark {
			@apply text-gray-900;
			&:focus, &:hover, &.Wrapper--active {
				@apply text-gray-900;
			}
			.Arrow {
				&:before, &:after {
					@apply bg-gray-900;
				}
				span {
					&:before {
						@apply bg-gray-900;
					}
				}
			}
		}
		&:focus {
			@apply outline-none;
		}
	}
	&.Wrapper--disabled {
		@apply opacity-50 pointer-events-none;
	}
	&:disabled {
		@apply pointer-events-none opacity-50;
	}
}
.Body {
	@apply flex items-center;
}
.Value, .Icon, .Arrow, .Loader {
	@apply mr-1;
	&:last-child {
		@apply mr-0;
	}
}
.Icon {
	@apply relative flex items-center justify-center;
	width:16px;
	height:16px;
	&:first-child:last-child {
		width:24px;
		height:24px;
	}
	svg {
		width:14px;
		height:14px;
	}
}
.Arrow {
	width:8px;
	height:16px;
	position:relative;
	//margin-left:-4px;
	&:before, &:after {
		content:"";
		position:absolute;
		left:0;
		width:7px;
		height:2px;
		top:50%;
		margin-top:-1px;
		@apply transform transition-move duration-300 ease-out-cubic bg-gray-900 rounded-full;
	}
	&:before {
		@apply rotate-45 -translate-y-0.5;
	}
	&:after {
		@apply -rotate-45 translate-y-0.5;
	}
	span {
		width:8px;
		height:16px;
		position:absolute;
		left:0;
		top:0;
		display:block;
		&:before {
			position:absolute;
			content:"";
			left:0;
			top:50%;
			width:3px;
			height:2px;
			margin-top:-1px;
			@apply transform transition-move duration-300 ease-out-cubic opacity-0 bg-gray-900 rounded-l-full;
		}
	}
}
.Counter {
	position:relative;
	@apply ml-1 pl-2 text-primary-500 font-bold;
	&:before {
		content:"";
		position:absolute;
		left:0;
		top:50%;
		width:1px;
		height:16px;
		@apply transform bg-gray-300 -translate-y-1/2;
	}
}
.File {
	@apply absolute inset-0 text-3xl opacity-0;
}