.Spinner {
	border:2px solid transparent;
	border-radius:50%;
	border-top:2px solid var(--color-gray-900);
	border-right:2px solid var(--color-gray-900);
	border-bottom:2px solid var(--color-gray-900);
	width:12px;
	height:12px;
	max-width:100%;
	max-height:100%;
	@apply animate-spin;
	&.Spinner--white {
		border-top-color:var(--color-white);
		border-right-color:var(--color-white);
		border-bottom-color:var(--color-white);
	}
	&.Spinner--primary {
		border-top-color:var(--color-primary-500);
		border-right-color:var(--color-primary-500);
		border-bottom-color:var(--color-primary-500);
	}
	&.Spinner--purple {
		border-top-color:var(--color-purple-500);
		border-right-color:var(--color-purple-500);
		border-bottom-color:var(--color-purple-500);
	}
	&.Spinner--green {
		border-top-color:var(--color-green-500);
		border-right-color:var(--color-green-500);
		border-bottom-color:var(--color-green-500);
	}
	&.Spinner--red {
		border-top-color:var(--color-red-500);
		border-right-color:var(--color-red-500);
		border-bottom-color:var(--color-red-500);
	}
	&.Spinner--orange {
		border-top-color:var(--color-orange-500);
		border-right-color:var(--color-orange-500);
		border-bottom-color:var(--color-orange-500);
	}
	&.Spinner--yellow {
		border-top-color:var(--color-yellow-500);
		border-right-color:var(--color-yellow-500);
		border-bottom-color:var(--color-yellow-500);
	}
	&.Spinner--blue {
		border-top-color:var(--color-blue-500);
		border-right-color:var(--color-blue-500);
		border-bottom-color:var(--color-blue-500);
	}
	&.Spinner--md {
		width:20px;
		height:20px;
	}
	&.Spinner--center {
		margin-left:auto;
		margin-right:auto;
	}
	&.Spinner--space-sm {
		@apply my-5;
	}
	&.Spinner--space-md {
		@apply my-10;
	}
	&.Spinner--space-lg {
		@apply my-20;
	}
}