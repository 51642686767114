.Editor {
	position:fixed;
	left:0;
	top:0;
	width:var(--editor-width);
	z-index:100;
	pointer-events:none;
	top:env(safe-area-inset-top);
	bottom:calc(50px + env(safe-area-inset-bottom));
	@screen lg {
		top:auto;
		bottom:auto;
		left:var(--sidebar-width);
		height:100vh;
		height:fill-available;
		margin-bottom:0;
	}
	:global(.open-editor) & {
		pointer-events:all;
		.Container {
			@apply opacity-100 translate-x-0;
		}
		.Helper {
			left:var(--editor-width);
		}
		&:hover {
			.Helper {
				@apply opacity-100;
			}
		}
	}
	@media (max-width: 991px) {
		pointer-events:all;
		.Container {
			@apply opacity-100 translate-x-0;
		}
		.Helper {
			left:var(--editor-width);
		}
		&:hover {
			.Helper {
				@apply opacity-0;
			}
		}
	}
	&.Editor--sidebar {
		.Container {
			width:calc(var(--editor-width) + var(--second-sidebar-width));
		}
		:global(.open-editor) & {
			.Helper {
				left:calc(var(--editor-width) + var(--second-sidebar-width));
			}
		}
		.Page {
			&:last-child {
				transform:translateX(var(--second-sidebar-width));
			}
			.PageHeader {
				& > div {
					@apply opacity-50 pointer-events-none;
				}
			}
		}
	}
}
.Helper {
	position:absolute;
	left:0;
	top:50%;
	width:30px;
	height:30px;
	margin:-15px 0 0 -15px;
	@apply transform transition-move duration-300 ease-out-cubic bg-white rounded-full shadow-button items-center justify-center hidden lg:flex;
	z-index:10;
	cursor:pointer;
	pointer-events:all;
	:global(.open-editor) & {
		@media (hover: hover) {
			@apply opacity-0;
		}
	}
	@media (hover: hover) {
		&:hover {
			& > * {
				@apply translate-x-1;
				:global(.open-editor) & {
					@apply -translate-x-1;
				}
			}
		}
	}
	& > * {
		@apply transition-move duration-300 ease-out-cubic;
		:global(.open-editor) & {
			@apply rotate-180;
		}
	}
}
.Container {
	position:absolute;
	left:0;
	top:0;
	width:var(--editor-width);
	height:100%;
	@apply opacity-0 bg-gray-100 transform -translate-x-0 transition-move duration-300 ease-out-cubic lg:shadow-box-light;
	overflow:hidden;
}
.Pages {
	position:relative;
	display:flex;
	height:100%;
	@apply transition-move duration-300 ease-out-cubic;
	&.Pages--no-transition {
		@apply transition-none;
	}
}
.Page {
	width:var(--editor-width);
	@apply transition-move duration-300 ease-out-cubic;
	@apply flex flex-col;
}
.PageHeader {
	height:var(--topbar-height);
	@apply border-b border-gray-300 bg-gray-100;
}
.PageBody {
	height:100%;
	overflow-y:scroll;
	@screen lg {
		overflow-y:auto;
	}
	scroll-behavior:smooth;
	@apply bg-white;
}
.Sidebar {
	position:absolute;
	left:calc(var(--second-sidebar-width) * -1);
	top:0;
	width:var(--second-sidebar-width);
	height:100%;
	z-index:1;
	@apply bg-white shadow-box-light flex flex-col;
}
.SidebarHeader {
	height:var(--topbar-height);
	@apply border-b border-gray-300 bg-gray-100;
}
.SidebarBody {
	height:100%;
	overflow-y:scroll;
	@screen lg {
		overflow-y:auto;
	}
	scroll-behavior:smooth;
	@apply bg-white;
}