.Wrapper {
	font-size:0;
	&.Wrapper--green {
		.Badge {
			@apply bg-green-200 text-green-600;
		}
	}
	&.Wrapper--blue {
		.Badge {
			@apply bg-blue-100 text-blue-600;
		}
	}
	&.Wrapper--red {
		.Badge {
			@apply bg-red-200 text-red-600;
		}
	}
	&.Wrapper--orange {
		.Badge {
			@apply bg-orange-200 text-orange-600;
		}
	}
	&.Wrapper--gray {
		.Badge {
			@apply bg-gray-200 text-gray-700;
		}
	}
	&.Wrapper--xs {
		.Badge {
			font-size:11px;
			line-height:14px;
			.Icon {
				&:first-child:last-child {
					width:14px;
					height:14px;
				}
				svg {
					width:10px;
					height:10px;
				}
			}
		}
	}
	&.Wrapper--sm {
		.Badge {
			@apply text-3xs;
			line-height:18px;
			.Icon {
				&:first-child:last-child {
					width:18px;
					height:18px;
				}
			}
		}
	}
	&.Wrapper--xl {
		.Badge {
			@apply text-xs px-3;
			line-height:36px;
			.Icon {
				&:first-child:last-child {
					width:36px;
					height:36px;
				}
				svg {
					width:16px;
					height:16px;
				}
			}
		}
	}
	&.Wrapper--rounded-full {
		.Badge {
			@apply rounded-full;
		}
	}
	&.Wrapper--icon-only {
		.Badge {
			padding-left:1px;
			padding-right:1px;
		}
	}
	&.Wrapper--absolute {
		.Badge {
			position:absolute;
			right:0;
			top:0;
		}
	}
}
.Badge {
	@apply font-medium text-2xs px-1.5 py-px rounded inline-block;
	line-height:20px;
}
.Body {
	@apply flex items-center;
}
.Label, .Icon {
	@apply mr-1;
	&:last-child {
		@apply mr-0;
	}
}
.Icon {
	@apply relative flex items-center justify-center;
	width:14px;
	height:14px;
	&:first-child:last-child {
		width:20px;
		height:20px;
	}
	svg {
		width:12px;
		height:12px;
	}
}